import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDQOIKYM8M9nfyHu5ufI0xXIm-685Utgr0",
    authDomain: "lunar-6ae32.firebaseapp.com",
    projectId: "lunar-6ae32",
    storageBucket: "lunar-6ae32.appspot.com",
    messagingSenderId: "398913364449",
    appId: "1:398913364449:web:9eee56d45316deea42f2a0",
    measurementId: "G-J1N78982PV"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };