import React from 'react';
import ProductCard from './ProductCard';
import '../styles/ProductList.scss';
import { ICompleteState, IErrorState, IGenerateSearchQueriesState, IInitialSearchState, IPrice, IProcessProductsState, IProductIdentifier, ISearchState } from '../types/lunar-api';
import { IGenericError, IUserFacingError, IUserFacingError_Generic } from '../types/lunar-errors-api';

interface Product {
  title: string;
  description: string;
  image: string;
  rating: number;
  link: string;
  price?: IPrice;
  marketRating?: number;
  marketRatingCount?: number;
  identifier: IProductIdentifier;
  uiRank?: number;
}

interface ProductListProps {
  products: Product[];
  state?: ISearchState;
  cardDisplayCount: number;
  setShowMoreEnabled: (value: boolean) => void;
}

const ProductList: React.FC<ProductListProps> = ({ products, state, cardDisplayCount, setShowMoreEnabled }) => {
  if (state === undefined) {
    return (
      <BasicLoadingState loadingMessage={""} />
    )
  }
  
  return (
    ISearchState.visit(state!, {
      generateSearchQueries: function (obj: IGenerateSearchQueriesState): React.JSX.Element {
        return <BasicLoadingState loadingMessage={"Generating search queries..."} />
      },
      initialSearch: function (obj: IInitialSearchState): React.JSX.Element {
        return <BasicLoadingState loadingMessage={"Initializing search..."} />
      },
      processProducts: function (obj: IProcessProductsState): React.JSX.Element {
        return <BasicLoadingState loadingMessage={"Processing products..."} />
      },
      complete: function (obj: ICompleteState): React.JSX.Element {
        if (products.length === 0) {
          return <BasicLoadingState loadingMessage={"Waiting for products..."} />
        }

        return <CompleteState 
          state={obj}
          products={products}
          cardDisplayCount={cardDisplayCount}
          setShowMoreEnabled={setShowMoreEnabled}
        />
      },
      error: function (obj: IErrorState): React.JSX.Element {
        return <ErrorState error={obj.userFacingError} />
      },
      unknown: function (obj: ISearchState): React.JSX.Element {
        return <BasicLoadingState loadingMessage={"Loading...."} />
      }
    })
  );
};


const CompleteState: React.FC<{state: ICompleteState, products: Product[], cardDisplayCount: number, setShowMoreEnabled: (a: boolean) => void; }> = ({ state, products, cardDisplayCount, setShowMoreEnabled }) => {
  var slicedProducts: Product[] = [];
  
  if (products.length !== 0) {
    products.sort((a, b) => b.rating - a.rating);

    const averageRating = products.map(a => a.rating).reduce((a, b) => a + b) / products.length;
    const minRating = 0.2 * averageRating;

    const filteredProducts: Product[] = products.filter(product => (product.price?.value !== undefined) && (product.price?.value !== 0) && (product.rating >= minRating));

    const maxProductsToShow = filteredProducts.length;
    slicedProducts = filteredProducts.slice(0, Math.min(cardDisplayCount, maxProductsToShow));

    slicedProducts.forEach((product, index) => {
      product.uiRank = index + 1;
    });

    if (cardDisplayCount >= maxProductsToShow) {
      setShowMoreEnabled(false);
    } else {
      setShowMoreEnabled(true);
    }
  } else {
    setShowMoreEnabled(false);
  }

  return (
      <div className="product-list-wrapper">
          <div className='product-list-items'>
            {slicedProducts.map((product, index) => (
              <ProductCard key={index} {...product} />
            ))}
          </div>
      </div>
  );
}

const BasicLoadingState: React.FC<{loadingMessage: string}> = ({ loadingMessage }) => {
  return (
    <div className="product-list-wrapper">
        <div className='product-list-items'>
          <SkeletonProductCard />
          <SkeletonProductCard />
          <SkeletonProductCard />
        </div>
    </div>
  );
}

const ErrorState: React.FC<{error: IUserFacingError | undefined | null}> = ({ error }) => {
  const defaultUnknownError = "Unknown error";
  const errorMessage: string = (error === undefined || error === null) ? defaultUnknownError : (
    IUserFacingError.visit(error, {
      generic: function (obj: IGenericError): string {
        return obj.message || defaultUnknownError;
      },
      unknown: function (obj: IUserFacingError_Generic): string {
        return defaultUnknownError;
      }
    })
  );
  
  return (
      <div className="search-state-wrapper">
          <span>ERROR: {errorMessage}</span>
      </div>
  );
}

const SkeletonProductCard: React.FC<{}> = () => {
  const dummyIdentifier: IProductIdentifier = {
    "type": "amazon",
    "amazon": "dummy"
  }

  return (
    <ProductCard title={''} description={''} image={''} rating={0} link={''} identifier={dummyIdentifier} isSkeleton={true} />
  );
}

export default ProductList;
