import React from 'react';
import '../styles/Navbar.scss';
import Logo from './Logo';
import { Button } from "@blueprintjs/core";

const Navbar: React.FC<{}> = () => {
  return (
    <div className="navbar">
      <div className="navbar-content">
        <Logo />
        <Button large={false}>
          Sign Up
        </Button>
      </div>
    </div>
  );
};

export default Navbar;