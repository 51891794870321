import { ICompleteState } from "./completeState";
import { IErrorState } from "./errorState";
import { IGenerateSearchQueriesState } from "./generateSearchQueriesState";
import { IInitialSearchState } from "./initialSearchState";
import { IProcessProductsState } from "./processProductsState";

export interface ISearchState_GenerateSearchQueries {
    'generateSearchQueries': IGenerateSearchQueriesState;
    'type': "generateSearchQueries";
}

export interface ISearchState_InitialSearch {
    'initialSearch': IInitialSearchState;
    'type': "initialSearch";
}

export interface ISearchState_ProcessProducts {
    'processProducts': IProcessProductsState;
    'type': "processProducts";
}

export interface ISearchState_Complete {
    'complete': ICompleteState;
    'type': "complete";
}

export interface ISearchState_Error {
    'error': IErrorState;
    'type': "error";
}

function isGenerateSearchQueries(obj: ISearchState): obj is ISearchState_GenerateSearchQueries {
    return (obj.type === "generateSearchQueries");
}

function generateSearchQueries(obj: IGenerateSearchQueriesState): ISearchState_GenerateSearchQueries {
    return {
        generateSearchQueries: obj,
        type: "generateSearchQueries",
    };
}

function isInitialSearch(obj: ISearchState): obj is ISearchState_InitialSearch {
    return (obj.type === "initialSearch");
}

function initialSearch(obj: IInitialSearchState): ISearchState_InitialSearch {
    return {
        initialSearch: obj,
        type: "initialSearch",
    };
}

function isProcessProducts(obj: ISearchState): obj is ISearchState_ProcessProducts {
    return (obj.type === "processProducts");
}

function processProducts(obj: IProcessProductsState): ISearchState_ProcessProducts {
    return {
        processProducts: obj,
        type: "processProducts",
    };
}

function isComplete(obj: ISearchState): obj is ISearchState_Complete {
    return (obj.type === "complete");
}

function complete(obj: ICompleteState): ISearchState_Complete {
    return {
        complete: obj,
        type: "complete",
    };
}

function isError(obj: ISearchState): obj is ISearchState_Error {
    return (obj.type === "error");
}

function error(obj: IErrorState): ISearchState_Error {
    return {
        error: obj,
        type: "error",
    };
}

export type ISearchState = ISearchState_GenerateSearchQueries | ISearchState_InitialSearch | ISearchState_ProcessProducts | ISearchState_Complete | ISearchState_Error;

export interface ISearchStateVisitor<T> {
    'generateSearchQueries': (obj: IGenerateSearchQueriesState) => T;
    'initialSearch': (obj: IInitialSearchState) => T;
    'processProducts': (obj: IProcessProductsState) => T;
    'complete': (obj: ICompleteState) => T;
    'error': (obj: IErrorState) => T;
    'unknown': (obj: ISearchState) => T;
}

function visit<T>(obj: ISearchState, visitor: ISearchStateVisitor<T>): T {
    if (isGenerateSearchQueries(obj)) {
        return visitor.generateSearchQueries(obj.generateSearchQueries);
    }
    if (isInitialSearch(obj)) {
        return visitor.initialSearch(obj.initialSearch);
    }
    if (isProcessProducts(obj)) {
        return visitor.processProducts(obj.processProducts);
    }
    if (isComplete(obj)) {
        return visitor.complete(obj.complete);
    }
    if (isError(obj)) {
        return visitor.error(obj.error);
    }
    return visitor.unknown(obj);
}

export const ISearchState = {
    isGenerateSearchQueries: isGenerateSearchQueries,
    generateSearchQueries: generateSearchQueries,
    isInitialSearch: isInitialSearch,
    initialSearch: initialSearch,
    isProcessProducts: isProcessProducts,
    processProducts: processProducts,
    isComplete: isComplete,
    complete: complete,
    isError: isError,
    error: error,
    visit: visit
};
