
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SearchPage from "./components/SearchPage";
import LoginForm from "./components/LoginForm";
import PrivateRoute from "./components/PrivateRoute";
import { AuthProvider } from "./context/AuthContext";
import Navbar from "./components/Navbar";
import './styles/App.scss';

const App: React.FC = () => {
  const handleTokenReceived = (token: string) => {
    console.log("Successful login");
  };

  return (
    <div className="app">
      <AuthProvider>
        <Navbar />
        <div className="content">
          <Router>
            <Routes>
              <Route path="/login" element={<LoginForm onTokenReceived={handleTokenReceived} />} />
              <Route
                path="/:searchId?"
                element={
                  <PrivateRoute>
                    <SearchPage />
                  </PrivateRoute>
                }
              />
            </Routes>
          </Router>
        </div>
      </AuthProvider>
    </div>
  );
};

export default App;
