import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import SearchBar from './SearchBar';
import { search } from '../services/productSearch';
import { useAuth } from '../context/AuthContext';
import '../styles/SearchPage.scss';
import SearchContent from './SearchContent';


const SearchPage: React.FC = () => {
  const { searchId: urlSearchId } = useParams<{ searchId?: string }>();
  const navigate = useNavigate();
  const auth = useAuth();

  const [searchId, setSearchId] = useState<string | undefined>(urlSearchId);
  const [searchSubmitError, setSearchSubmitError] = useState<string | null>(null);

  // If the token is null and not loading, redirect to login page
  useEffect(() => {
    if (!auth.loading && !auth.token) {
      navigate("/login");
    }
  }, [auth.token, auth.loading, navigate]);

  const handleSearchSubmit = async (query: string) => {
    console.log("Handle search submit");

    if (!auth.token) return;
    
    console.log('Search query submitted:', query);

    try {
      const searchId = await search(query, auth.token);
      setSearchId(searchId);
      navigate(`/${searchId}`);
    } catch (error) {
      console.error('Failed to submit search:', error);
      setSearchSubmitError('Failed to submit search.');
      setSearchId(undefined);
      navigate(`/`);
    }
  };

  const handleBackToSearch = () => {
    setSearchId(undefined);
    setSearchSubmitError(null);
    navigate('/');
  };

  return (
    <div className="search-page">
      {
        (auth.loading || auth.token === null) ? (
          <span>Logging in...</span>
        ) : (searchId === undefined) ? (
            <div className="new-search-content">
                {searchSubmitError && <div className="error-message">{searchSubmitError}</div>}
                <SearchBar onSubmit={handleSearchSubmit} />
            </div>
        ) : (
          <SearchContent searchId={searchId} token={auth.token!} handleBackToSearch={handleBackToSearch} />
        )
      }
    </div>
  );
};

export default SearchPage;



