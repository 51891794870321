import { ICompletePreAnalysisContent } from "./completePreAnalysisContent";
import { IErrorPreAnalysisContent } from "./errorPreAnalysisContent";
import { IInProgressPreAnalysisContent } from "./inProgressPreAnalysisContent";

export interface IPerplexityPreAnalysisContent_InProgress {
    'inProgress': IInProgressPreAnalysisContent;
    'type': "inProgress";
}

export interface IPerplexityPreAnalysisContent_Complete {
    'complete': ICompletePreAnalysisContent;
    'type': "complete";
}

export interface IPerplexityPreAnalysisContent_Error {
    'error': IErrorPreAnalysisContent;
    'type': "error";
}

function isInProgress(obj: IPerplexityPreAnalysisContent): obj is IPerplexityPreAnalysisContent_InProgress {
    return (obj.type === "inProgress");
}

function inProgress(obj: IInProgressPreAnalysisContent): IPerplexityPreAnalysisContent_InProgress {
    return {
        inProgress: obj,
        type: "inProgress",
    };
}

function isComplete(obj: IPerplexityPreAnalysisContent): obj is IPerplexityPreAnalysisContent_Complete {
    return (obj.type === "complete");
}

function complete(obj: ICompletePreAnalysisContent): IPerplexityPreAnalysisContent_Complete {
    return {
        complete: obj,
        type: "complete",
    };
}

function isError(obj: IPerplexityPreAnalysisContent): obj is IPerplexityPreAnalysisContent_Error {
    return (obj.type === "error");
}

function error(obj: IErrorPreAnalysisContent): IPerplexityPreAnalysisContent_Error {
    return {
        error: obj,
        type: "error",
    };
}

export type IPerplexityPreAnalysisContent = IPerplexityPreAnalysisContent_InProgress | IPerplexityPreAnalysisContent_Complete | IPerplexityPreAnalysisContent_Error;

export interface IPerplexityPreAnalysisContentVisitor<T> {
    'inProgress': (obj: IInProgressPreAnalysisContent) => T;
    'complete': (obj: ICompletePreAnalysisContent) => T;
    'error': (obj: IErrorPreAnalysisContent) => T;
    'unknown': (obj: IPerplexityPreAnalysisContent) => T;
}

function visit<T>(obj: IPerplexityPreAnalysisContent, visitor: IPerplexityPreAnalysisContentVisitor<T>): T {
    if (isInProgress(obj)) {
        return visitor.inProgress(obj.inProgress);
    }
    if (isComplete(obj)) {
        return visitor.complete(obj.complete);
    }
    if (isError(obj)) {
        return visitor.error(obj.error);
    }
    return visitor.unknown(obj);
}

export const IPerplexityPreAnalysisContent = {
    isInProgress: isInProgress,
    inProgress: inProgress,
    isComplete: isComplete,
    complete: complete,
    isError: isError,
    error: error,
    visit: visit
};
