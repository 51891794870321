import React from 'react';
import '../styles/LoadingBar.scss';
import { Callout, Intent, ProgressBar } from "@blueprintjs/core";
import { ICompleteState, IErrorState, IGenerateSearchQueriesState, IInitialSearchState, IProcessProductsState, ISearchState } from '../types/lunar-api';

interface LoadingBarStatus {
    intent: "Loading" | "Complete" | "Error";
    message: string;
    loadingPercent: number | undefined;
}

interface LoadingBarProps {
    state?: ISearchState;
  }  

const LoadingBar: React.FC<LoadingBarProps> = ({ state }) => {
    if (state === undefined) {
        return (<></>)
    }

    const status: LoadingBarStatus = ISearchState.visit(state, {
        generateSearchQueries: function (obj: IGenerateSearchQueriesState): LoadingBarStatus {
            return {
                intent: "Loading",
                message: "Generating Search Terms",
                loadingPercent: 0.1,
            };
        },
        initialSearch: function (obj: IInitialSearchState): LoadingBarStatus {
            return {
                intent: "Loading",
                message: "Starting Search",
                loadingPercent: 0.4,
            };
        },
        processProducts: function (obj: IProcessProductsState): LoadingBarStatus {
            const processedProducts = Object.keys(obj.analysedProducts).length;

            const loadingPercent = generateLoadingPercent(processedProducts, obj.uniqueProductsFound, 0.5, 1);

            return {
                intent: "Loading",
                message: "Processing Products (" + processedProducts + " / " + obj.uniqueProductsFound + ")",
                loadingPercent: loadingPercent,
            };
        },
        complete: function (obj: ICompleteState): LoadingBarStatus {
            return {
                intent: "Complete",
                message: "",
                loadingPercent: 1,
            };
        },
        error: function (obj: IErrorState): LoadingBarStatus {
            return {
                intent: "Error",
                message: obj.userFacingError?.generic.message ?? "Unexpected error occured",
                loadingPercent: undefined,
            };
        },
        unknown: function (obj: ISearchState): LoadingBarStatus {
            return {
                intent: "Loading",
                message: "Loading...",
                loadingPercent: undefined,
            };
        }
    });

    if (status.intent === "Complete") {
        return (<></>);
    }

    return (
        <Callout className='loading-bar' intent={(status.intent === "Error") ? Intent.DANGER : Intent.NONE} title={status.message}>
            {(status.loadingPercent !== undefined) ? <ProgressBar value={status.loadingPercent} intent={Intent.NONE}/> : (<></>)}
        </Callout>
    );
};

function generateLoadingPercent(current: number, total: number, minPercent: number, maxPercent: number) {
    const diff = maxPercent - minPercent;

    const percent = current / total;

    return minPercent + (percent * diff);
}


export default LoadingBar;

