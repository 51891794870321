import React, { useState, useEffect } from 'react';
import Typewriter from 'typewriter-effect';
import '../styles/ExampleTypewriter.scss';

interface ExampleTypewriterProps {
  examples: string[]; // Array of examples
  pausePerKey?: number; 
  pauseBetweenExamples?: number;
}

const ExampleTypewriter: React.FC<ExampleTypewriterProps> = ({
  examples,
  pausePerKey = 30,
  pauseBetweenExamples = 15000,
}) => {
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState<number>(0); // Specify type for state

  useEffect(() => {
    // Timer to update the quote index after the animation and pause duration
    const timer = setTimeout(() => {
      setCurrentQuoteIndex((prevIndex) =>
        prevIndex === examples.length - 1 ? 0 : prevIndex + 1
      );
    }, examples[currentQuoteIndex].length * pausePerKey + pauseBetweenExamples);

    return () => clearTimeout(timer);
  }, [currentQuoteIndex, examples, pausePerKey, pauseBetweenExamples]);

  return (
    <div className='example-typewriter'>
      <Typewriter
        key={currentQuoteIndex} // Ensure the Typewriter re-renders for each quote
        onInit={(typewriter) => {
          typewriter
            .typeString(examples[currentQuoteIndex])
            .start();
        }}
        options={{
          delay: pausePerKey,
          deleteSpeed: 10
        }}
      />
    </div>
  );
};

export default ExampleTypewriter;
