import React from 'react';

const Logo: React.FC<{}> = () => {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M25.5909 21.9319C24.9148 23.497 23.8189 24.8252 22.4351 25.7566C21.0513 26.6881 19.4385 27.183 17.7909 27.1819C13.0564 27.1819 9.22726 23.1933 9.22726 18.2615C9.22726 14.6478 11.2891 11.5342 14.2673 10.1365C13.7764 11.2615 13.5036 12.5001 13.5036 13.8069C13.5036 16.1728 14.4059 18.4417 16.0119 20.1146C17.6179 21.7876 19.796 22.7274 22.0673 22.7274C23.3218 22.7274 24.5109 22.4433 25.5909 21.9319Z" fill="#5E5E5E"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M39.375 34.5751L32.7 27.8751C34.5092 25.0736 35.4726 21.81 35.475 18.4751C35.475 8.8001 27.65 0.975098 17.975 0.975098C8.30001 0.975098 0.475006 8.8001 0.475006 18.4751C0.475006 28.1501 8.30001 35.9751 17.975 35.9751C21.45 35.9751 24.675 34.9251 27.375 33.2001L34.075 39.8751C34.7778 40.5779 35.7311 40.9728 36.725 40.9728C37.7189 40.9728 38.6722 40.5779 39.375 39.8751C40.0778 39.1723 40.4727 38.219 40.4727 37.2251C40.4727 36.2312 40.0778 35.2779 39.375 34.5751ZM17.975 30.9751C11.075 30.9751 5.47501 25.3751 5.47501 18.4751C5.47501 11.5751 11.075 5.9751 17.975 5.9751C24.875 5.9751 30.475 11.5751 30.475 18.4751C30.475 25.3751 24.875 30.9751 17.975 30.9751Z" fill="#5E5E5E"/>
    </svg>
  );
};

export default Logo;
