export interface IProductIdentifier_Amazon {
    'amazon': string;
    'type': "amazon";
}

function isAmazon(obj: IProductIdentifier): obj is IProductIdentifier_Amazon {
    return (obj.type === "amazon");
}

function amazon(obj: string): IProductIdentifier_Amazon {
    return {
        amazon: obj,
        type: "amazon",
    };
}

export type IProductIdentifier = IProductIdentifier_Amazon;

export interface IProductIdentifierVisitor<T> {
    'amazon': (obj: string) => T;
    'unknown': (obj: IProductIdentifier) => T;
}

function visit<T>(obj: IProductIdentifier, visitor: IProductIdentifierVisitor<T>): T {
    if (isAmazon(obj)) {
        return visitor.amazon(obj.amazon);
    }
    return visitor.unknown(obj);
}

export const IProductIdentifier = {
    isAmazon: isAmazon,
    amazon: amazon,
    visit: visit
};
