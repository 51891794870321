import React from 'react';
import '../styles/PerplexityAnalysis.scss';
import { ICompletePreAnalysisContent, IErrorPreAnalysisContent, IInProgressPreAnalysisContent, IPerplexityPreAnalysis, IPerplexityPreAnalysisContent } from '../types/lunar-api';
import { Icon, Intent } from '@blueprintjs/core';
import classNames from 'classnames';
import { SKELETON } from '@blueprintjs/core/lib/esm/common/classes';


interface PerplexityAnalysisProps {
  analysis?: IPerplexityPreAnalysis;
}  

const PerplexityAnalysis: React.FC<PerplexityAnalysisProps> = ({ analysis }) => {
    return (
        <div className="perplexity-analysis-wrapper">
            <div className="perplexity-analysis-header">
                <Icon icon="predictive-analysis" size={16} intent={Intent.NONE} className='perplexity-analysis-header-icon'/>
                <span>Insights</span>
            </div>
            <div>
                Here’s what we recommend thinking about when choosing your product: 
            </div>
            {(analysis === undefined) ? (
                <InProgressPerplexityAnalysis />
            ) : (
                analysisContent(analysis)
            )}
        </div>
    )
};

function analysisContent(analysis: IPerplexityPreAnalysis): React.JSX.Element {
    return IPerplexityPreAnalysisContent.visit(analysis.content, {
        inProgress: function (_: IInProgressPreAnalysisContent): React.JSX.Element {
            return <InProgressPerplexityAnalysis />
        },
        complete: function (obj: ICompletePreAnalysisContent): React.JSX.Element {
            return <CompletePerplexityAnalysis content={obj.content} />
        },
        error: function (_: IErrorPreAnalysisContent): React.JSX.Element {
            return <ErrorPerplexityAnalysis />
        },
        unknown: function (_: IPerplexityPreAnalysisContent): React.JSX.Element {
            throw new Error('Unknown type found');
        }
    })
}

const InProgressSkeleton: React.FC<{}> = () => {
    return (
        <div className={classNames("perplexity-analysis-card")}>
            <h3 className={SKELETON}>Loading...</h3>
            <ul className={SKELETON}>
                <li className={SKELETON}>Loading...</li>
                <li className={SKELETON}>Loading...</li>
                <li className={SKELETON}>Loading...</li>
            </ul>
        </div>
    )
}

const InProgressPerplexityAnalysis: React.FC<{}> = () => {
    return (
        <div className="perplexity-analysis-wrapper">
            <InProgressSkeleton />
            <InProgressSkeleton />
            <InProgressSkeleton />
        </div>
    );
}

const CompletePerplexityAnalysis: React.FC<ICompletePreAnalysisContent> = ({ content }) => {
    return (
        <div className="perplexity-analysis-wrapper">
            {content.map((card, index) => (
                <div key={"perplexity-analysis-card" + index} className="perplexity-analysis-card">
                    <h3 key={"perplexity-analysis-card-h3" + index} >{card.title}</h3>
                    <ul key={"perplexity-analysis-card-ul" + index} >
                        {card.bullets.map((bullet, j) => (
                            <li key={index + "-" + j} >{bullet}</li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
}


const ErrorPerplexityAnalysis: React.FC<{}> = () => {
    return (
        <div className="perplexity-analysis-wrapper">
            Error Loading Analysis
        </div>
    );
}

export default PerplexityAnalysis;
