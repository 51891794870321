export namespace PerplexityErrorType {
    export type TIMEOUT = "TIMEOUT";
    export type NOT_FOUND = "NOT_FOUND";
    export type ERROR_RESPONSE = "ERROR_RESPONSE";

    export const TIMEOUT = "TIMEOUT" as "TIMEOUT";
    export const NOT_FOUND = "NOT_FOUND" as "NOT_FOUND";
    export const ERROR_RESPONSE = "ERROR_RESPONSE" as "ERROR_RESPONSE";
}

export type PerplexityErrorType = keyof typeof PerplexityErrorType;
