import { IGetPerplexityAnalysisResponse, IGetSearchResponse, IGetSearchStateResponse, IProduct, ISearchRequest, ISearchResponse } from '../types/lunar-api';
import { IHttpApiBridge, DefaultHttpApiBridge } from 'conjure-client';
import { SearchService } from '../types/lunar-api';

const API_URL = 'https://lunar-server-x2zposecwa-nw.a.run.app/api';
const BAD_REVIEW_RATING_MODIFIER = 0.6;

function getSearchService(token: string) {
  const bridge: IHttpApiBridge = new DefaultHttpApiBridge({
    baseUrl: API_URL,
    userAgent: {
        productName: "lunarFrontend",
        productVersion: "0.0.1"
    },
    token: token
  });

  return new SearchService(bridge);
}

export const search = async (query: string, token: string): Promise<string> => {
  const request: ISearchRequest = { productDescription: query };
  const response: ISearchResponse = await getSearchService(token).triggerSearch(request);

  return response.searchId;
}

export const getSearchState = async (searchId: string, token: string): Promise<IGetSearchStateResponse> => {
  return await getSearchService(token).getSearchState(searchId);
};

export const getSearchResults = async (searchId: string, token: string): Promise<IGetSearchResponse> => {
  return adjustForReviewQuality(await getSearchService(token).getSearch(searchId));
};

export const getPerplexityPreAnalysis = async (requestId: string, token: string): Promise<IGetPerplexityAnalysisResponse> => {
  return getSearchService(token).getPerplexityAnalysis(requestId);
};

export const adjustForReviewQuality = (searchResponse: IGetSearchResponse): IGetSearchResponse => {
  return {
    "title": searchResponse.title,
    "products": searchResponse.products.map(product => {
      const baseRating = product.analysis.rating;

      if (highQualityReviews({ product: product.product })) {
        return product;
      }

      return {
        "analysis": {
          ...product.analysis,
          rating: baseRating * BAD_REVIEW_RATING_MODIFIER
        },
        "product": product.product
      }
    })
  }
}

/**
 * Determine whether we consider the reviews for the product to be high quality.
 */
function highQualityReviews({ product }: { product: IProduct; }): boolean {
  if ((product.totalReviews ?? 0) === 0) {
    return false;
  }

  if ((product.totalReviews ?? 0) < 100) {
    return false;
  }

  if (product.rating === "NaN") {
    return false;
  }

  if ((product.rating ?? 0) < 4) {
    return false;
  }

  return true;
}
