import { IGetPerplexityAnalysisResponse } from "./getPerplexityAnalysisResponse";
import { IGetSearchResponse } from "./getSearchResponse";
import { IGetSearchStateResponse } from "./getSearchStateResponse";
import { ISearchRequest } from "./searchRequest";
import { ISearchResponse } from "./searchResponse";
import { IHttpApiBridge } from "conjure-client";

/**
 * Constant reference to `undefined` that we expect to get minified and therefore reduce total code size
 */
const __undefined: undefined = undefined;

export interface ISearchService {
    triggerSearch(request: ISearchRequest): Promise<ISearchResponse>;
    getSearch(id: string): Promise<IGetSearchResponse>;
    getSearchState(id: string): Promise<IGetSearchStateResponse>;
    getPerplexityAnalysis(id: string): Promise<IGetPerplexityAnalysisResponse>;
}

export class SearchService {
    constructor(private bridge: IHttpApiBridge) {
    }

    public triggerSearch(request: ISearchRequest): Promise<ISearchResponse> {
        return this.bridge.call<ISearchResponse>(
            "SearchService",
            "triggerSearch",
            "POST",
            "/search/v1",
            request,
            __undefined,
            __undefined,
            __undefined,
            __undefined,
            __undefined
        );
    }

    public getSearch(id: string): Promise<IGetSearchResponse> {
        return this.bridge.call<IGetSearchResponse>(
            "SearchService",
            "getSearch",
            "GET",
            "/search/v1/{id}",
            __undefined,
            __undefined,
            __undefined,
            [
                id,
            ],
            __undefined,
            __undefined
        );
    }

    public getSearchState(id: string): Promise<IGetSearchStateResponse> {
        return this.bridge.call<IGetSearchStateResponse>(
            "SearchService",
            "getSearchState",
            "GET",
            "/search/v1/{id}/state",
            __undefined,
            __undefined,
            __undefined,
            [
                id,
            ],
            __undefined,
            __undefined
        );
    }

    public getPerplexityAnalysis(id: string): Promise<IGetPerplexityAnalysisResponse> {
        return this.bridge.call<IGetPerplexityAnalysisResponse>(
            "SearchService",
            "getPerplexityAnalysis",
            "GET",
            "/search/v1/perplexity/{id}",
            __undefined,
            __undefined,
            __undefined,
            [
                id,
            ],
            __undefined,
            __undefined
        );
    }
}
