import { IGenericError } from "./genericError";

export interface IUserFacingError_Generic {
    'generic': IGenericError;
    'type': "generic";
}

function isGeneric(obj: IUserFacingError): obj is IUserFacingError_Generic {
    return (obj.type === "generic");
}

function generic(obj: IGenericError): IUserFacingError_Generic {
    return {
        generic: obj,
        type: "generic",
    };
}

export type IUserFacingError = IUserFacingError_Generic;

export interface IUserFacingErrorVisitor<T> {
    'generic': (obj: IGenericError) => T;
    'unknown': (obj: IUserFacingError) => T;
}

function visit<T>(obj: IUserFacingError, visitor: IUserFacingErrorVisitor<T>): T {
    if (isGeneric(obj)) {
        return visitor.generic(obj.generic);
    }
    return visitor.unknown(obj);
}

export const IUserFacingError = {
    isGeneric: isGeneric,
    generic: generic,
    visit: visit
};
