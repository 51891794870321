import React, { useState } from 'react';
import '../styles/SearchBar.scss';
import { Button, TextArea } from '@blueprintjs/core';
import ExampleTypewriter from './ExampleTypewriter';

interface SearchBarProps {
  onSubmit: (query: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSubmit }) => {
  const [query, setQuery] = useState('');
  const [hasSearchStarted, setHasSearchStarted] = useState(false);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (query.trim() !== '') {
      onSubmit(query);
      setHasSearchStarted(true);
    }
  };

  return (
    <div className="search-bar-wrapper">
      <form className="search-bar-form" onSubmit={handleSubmit}>
        <h1>Find Your Perfect Product</h1>
        <div className='search-bar-form-elements'>
          <TextArea className="search-bar-input" placeholder="I am looking for..." 
            onChange={(e) => setQuery(e.target.value)} 
            rows={4}/>
          <Button className='search-bar-submit-button' type="submit" loading={hasSearchStarted}>Search</Button>
        </div>
        <ExampleTypewriter examples={[
          '“A new laptop. I want to do high-end video editing, gaming and watch Netflix. I do not care about battery life.”',
          '“A non-streaky cleaning product for my mirrored cupboard doors.  Should be easy to use and non-toxic.”',
          '“Yellow, lace-y dress for a size 14 female. No sleeves, and a fashionable slit up the side of one leg.”'
          ]}
        />
      </form>
    </div>
  );
};

export default SearchBar;