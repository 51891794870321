import { Button, Icon, Intent } from "@blueprintjs/core";
import PerplexityAnalysis from "./PerplexityAnalysis";
import { ICompleteState, IErrorState, IGenerateSearchQueriesState, IInitialSearchState, IPerplexityPreAnalysis, IProcessProductsState, ISearchState, PerplexityErrorType } from "../types/lunar-api";
import ProductList from "./ProductList";
import { getPerplexityPreAnalysis, getSearchResults, getSearchState } from "../services/productSearch";
import { useEffect, useState } from "react";
import { Product } from "../types/Product";
import '../styles/SearchContent.scss';
import { SKELETON } from "@blueprintjs/core/lib/esm/common/classes";
import classNames from "classnames";
import LoadingBar from "./LoadingBar";

interface SearchContentProps {
    token: string;
    searchId: string;
    handleBackToSearch: () => void;
}

const SearchContent: React.FC<SearchContentProps> = ({ token, searchId, handleBackToSearch }) => {
    const pollFrequencyMs: number = 500;
    const cardDisplayCountExtendAmount: number = 6;

    const [searchState, setSearchState] = useState<ISearchState | undefined>(undefined);
    const [products, setProducts] = useState<Product[]>([]);
    const [userQuery, setUserQuery] = useState<string | undefined>(undefined);

    const [perplexityRequestId, setPerplexityRequestId] = useState<string | undefined>(undefined);
    const [perplexityAnalysis, setPerplexityAnalysis] = useState<IPerplexityPreAnalysis | undefined>(undefined);

    const [cardDisplayCount, setCardDisplayCount] = useState(3);
    const [showMoreEnabled, setShowMoreEnabled] = useState(false);
  
    const extendCardDisplayCount = () => {
        setCardDisplayCount(cardDisplayCount + cardDisplayCountExtendAmount);
    }
    
    useEffect(() => {
        if (perplexityRequestId === undefined) return;
    
        let isComplete = false;
        let timeoutId: NodeJS.Timeout | null = null;
        console.log("Starting perplexity analysis polling");
    
        const pollPerplexityAnalysis = async () => {
            try {
                const value = await getPerplexityPreAnalysis(perplexityRequestId!, token);
        
                if (value.analysis.content.type === "complete") {
                    isComplete = true;
                    console.log("Perplexity analysis complete");
                }

                setPerplexityAnalysis(value.analysis);
        
                if (!isComplete) {
                    timeoutId = setTimeout(pollPerplexityAnalysis, pollFrequencyMs);
                }
            } catch (reason) {
                console.error(reason);
                setPerplexityAnalysis({
                    id: perplexityRequestId,
                    content: {
                        type: 'error',
                        error: {
                            errorType: PerplexityErrorType.NOT_FOUND,
                            errorMessage: "Error thrown",
                        },
                    },
                });
            }
        };
  
        pollPerplexityAnalysis();
  
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };

    }, [token, perplexityRequestId]);

    useEffect(() => {
        let isComplete = false;
        let timeoutId: NodeJS.Timeout | null = null;

        const pollSearchState = async () => {
            try {
                if (isComplete) return;

                console.log("Getting search state from server, isComplete:", isComplete);
                
                const response = await getSearchState(searchId, token);
                console.log("Retrieved search state from server");
                const state = response.state;

                const maybeSetUserQuery = (query: string | undefined) => {
                    if (userQuery === undefined && query !== undefined) {
                        setUserQuery(query!);
                    }
                }

                setSearchState(state);
                
                await ISearchState.visit(state, {
                    generateSearchQueries: function (_: IGenerateSearchQueriesState): void {},
                    initialSearch: function (state: IInitialSearchState): void {
                        maybeSetUserQuery(state.searchQueries[0])
                        setPerplexityRequestId(state.perplexityRequestId);
                    },
                    processProducts: function (obj: IProcessProductsState): void {
                        maybeSetUserQuery(obj.searchQueries[0])
                        setPerplexityRequestId(obj.perplexityRequestId);
                    },
                    complete: async function (obj: ICompleteState): Promise<void> {
                        isComplete = true;
                        if (products.length === 0) {
                            setPerplexityRequestId(obj.perplexityRequestId);
                            await getSearchResults(searchId, token).then(searchResults => {
                                maybeSetUserQuery(searchResults.title);

                                const products: Product[] = searchResults.products.map(product => ({
                                    title: product.product.title,
                                    description: product.analysis.analysis,
                                    image: product.product.imageUrls[0],
                                    rating: product.analysis.rating,
                                    link: product.product.link,
                                    price: product.product.price,
                                    marketRating: getRating(product.product.rating),
                                    marketRatingCount: product.product.totalReviews ?? undefined,
                                    identifier: product.product.identifier
                                }));

                                setProducts(products);
                            });
                        }
                    },
                    error: function (obj: IErrorState): void {
                        const message = obj.userFacingError?.generic.message ?? "Unknown error occurred";
                        console.error('Search error:', message);     
                        isComplete = true;
                    },
                    unknown: function (obj: ISearchState): void {}
                });

                if (!isComplete) {
                    timeoutId = setTimeout(pollSearchState, pollFrequencyMs);
                }

            } catch (error) {
                isComplete = true;
                console.error('Failed to get search state:', error);
                setSearchState({
                    "type": "error",
                    "error": {
                        "searchId": searchId,
                        "userFacingError": {
                            "type": "generic",
                            "generic": {
                                "message": "Failed to load search"
                            }
                        }
                    }
                });
            } 
        };

        pollSearchState();
  
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [
        token,
        searchId,
        userQuery,
        products,
    ]);

    return (
        <div className="search-content">
          <div className='search-content-sidebar'>
            <div className='search-content-sidebar-new-button'>
              <Button icon="arrow-left" onClick={handleBackToSearch} large={false}>
                New Search
              </Button>
            </div>
            <PerplexityAnalysis analysis={perplexityAnalysis} />
          </div>
          <div className='search-content-body'>
            <div className='search-content-query'>
              <Icon icon="search" size={16} intent={Intent.NONE} />
              {(userQuery === undefined) ? (
                <span className={classNames(SKELETON, "search-content-query-skeleton")}>Loading...</span>
              ) : (
                <span>{userQuery}</span>
              )}
            </div>
            <LoadingBar state={searchState} />
            <ProductList state={searchState} products={products} cardDisplayCount={cardDisplayCount} setShowMoreEnabled={setShowMoreEnabled} />
            <div className='search-content-more-results-button'>
                <Button icon="chevron-down" onClick={extendCardDisplayCount} text="More Results" disabled={!showMoreEnabled}/>
            </div>
          </div>
        </div>
    );
}

function getRating(rating: "NaN" | number | null | undefined): number | undefined {
    if (rating === null || rating === "NaN") {
    return undefined;
    }

    return rating;
}

export default SearchContent;